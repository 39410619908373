import { MISAction, MISReducerState } from "./typings";
import { MISUploadActionType, MISUploadRequestActionType } from "./constants";
import {
  loadableErrored,
  loadableInProgress,
  loadableLoaded,
  loadableNotInitiated,
  hasLoadableLoaded,
} from "../loadable/loadable";
import { AuthLoginRequestActionType } from "../auth/constants";
import { MISUpdateTransactionsRequestActionType } from "../transaction/constants";
import { mergeArraysByKey } from "../../utils/array";

export const defaultState: MISReducerState = {
  misInputLoadable: loadableNotInitiated(),
  misUploadLoadable: loadableNotInitiated(),
};

export const misReducer = (
  state: MISReducerState = defaultState,
  action: MISAction
): MISReducerState => {
  switch (action.type) {
    case MISUploadActionType.successful:
      return {
        ...state,
        misInputLoadable: loadableLoaded(action.payload),
      };

    case MISUploadRequestActionType.loading:
      return {
        ...state,
        misUploadLoadable: loadableInProgress(),
      };

    case MISUploadRequestActionType.successful:
      return {
        ...state,
        misUploadLoadable: loadableLoaded(action.payload),
      };

    case MISUploadRequestActionType.failed:
      return {
        ...state,
        misUploadLoadable: loadableErrored(action.payload),
      };

    case MISUpdateTransactionsRequestActionType.successful:
      if (!hasLoadableLoaded(state.misUploadLoadable)) {
        return { ...state };
      }

      return {
        ...state,
        misUploadLoadable: {
          ...state.misUploadLoadable,
          data: {
            ...state.misUploadLoadable.data,
            data: mergeArraysByKey(
              state.misUploadLoadable.data.data,
              action.payload.data,
              "platformBillID"
            ),
          },
        },
      };

    case MISUploadRequestActionType.reset:
    case AuthLoginRequestActionType.reset:
      return {
        ...state,
        misInputLoadable: loadableNotInitiated(),
        misUploadLoadable: loadableNotInitiated(),
      };

    default:
      return { ...state };
  }
};
