import { WorkflowAction, WorkflowReducerState } from "./typings";
import { MsgTypeActionType } from "./constants";
import {
    loadableErrored,
    loadableInProgress,
    loadableLoaded,
    loadableNotInitiated,
} from "../loadable/loadable";
import { AuthLoginRequestActionType } from "../auth/constants";


export const defaultState: WorkflowReducerState = {
    workflowLoadable: loadableNotInitiated(),
};


export const workflowReducer = (
    state: WorkflowReducerState = defaultState,
    action: WorkflowAction
): WorkflowReducerState => {
    switch (action.type) {
        case MsgTypeActionType.loading:
            return {
                ...state,
                workflowLoadable: loadableInProgress(),
            };

        case MsgTypeActionType.successful:
            return {
                ...state,
                workflowLoadable: loadableLoaded(action.payload),
            };

        case MsgTypeActionType.failed:
            return {
                ...state,
                workflowLoadable: loadableErrored(action.payload),
            };

        case MsgTypeActionType.reset:
        case AuthLoginRequestActionType.reset:
            return {
                ...state,
                workflowLoadable: loadableNotInitiated(),
            };

        default:
            return { ...state };
    }
};
