import React, { ChangeEvent, useState } from "react";
import Papa from "papaparse";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

import { MISUploadActionType, PaymentChannel } from "../../data/MIS/constants";
import { MISData } from "../../data/MIS/typings";
import { uploadMIS } from "../../data/MIS/actions";
import { Routes } from "../../App";
import { RootState } from "../../data/reducers";
import {
  isLoadableInProgress,
  hasLoadableErrored,
} from "../../data/loadable/loadable";

export const ClearingMISUpload = () => {
  const [paymentChannel, setPaymentChannel] = useState<PaymentChannel | "">("");
  const misUploadLoadable = useSelector(
    (state: RootState) => state.misReducer.misUploadLoadable
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePCOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPaymentChannel(event.currentTarget.value as PaymentChannel);
  };

  const handleFileOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (paymentChannel && files?.length) {
      Papa.parse<MISData>(files[0], {
        complete: (result) => {
          dispatch({
            type: MISUploadActionType.successful,
            payload: {
              paymentChannel,
              mis: result.data,
            },
          });

          dispatch(uploadMIS(paymentChannel, result.data))
            // @ts-ignore
            .then(() => {
              history.push(Routes.CLEARING_TRANSACTION);
            });
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Step 1: Select the payment channel</Form.Label>
            <Form.Control
              as="select"
              name="paymentChannel"
              onChange={handlePCOnChange}
              value={paymentChannel}
            >
              <option value={""} disabled>
                Select the channel
              </option>
              {Object.keys(PaymentChannel)
                .filter((pc) => pc !== "UPIDL")
                .map((pc) => {
                  return (
                    <option key={pc} value={pc.toLowerCase()}>
                      {pc}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4}>
          {paymentChannel && (
            <Form.Group>
              <Form.Label>Step 2: Upload MIS</Form.Label>
              <Form.File onChange={handleFileOnChange} accept=".csv" />
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoadableInProgress(misUploadLoadable) && (
            <Spinner animation="border" />
          )}
          {hasLoadableErrored(misUploadLoadable) && (
            <Alert variant="danger">
              Upload file failed. Please recheck.
              <br />
              Detail: {misUploadLoadable.error.title}
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
