export const mergeArraysByKey = <ItemType>(
  array1: ItemType[],
  array2: ItemType[],
  key: keyof ItemType
) => {
  let newState = [...array1];
  array2.forEach((item) => {
    const indexOfItem = newState.findIndex((i) => i[key] === item[key]);
    if (indexOfItem >= 0) {
      newState = [
        ...newState.slice(0, indexOfItem),
        item,
        ...newState.slice(indexOfItem + 1),
      ];
    } else {
      newState = [...newState, item];
    }
  });
  return newState;
};
