import { AxiosResponse, AxiosError } from "axios";
import qs from "query-string";

import { AuthLoginFormPayload, AuthLoginCall } from "./typings";
import { AppThunk, AuthApiError } from "../api/typings";
import { AuthLoginRequestActionType } from "./constants";
import { generateErrorFromResponse } from "../api/utils";
import { authAxiosInstance } from "../api/axios";
import { ApiUrl } from "../api/constants";

export const authLogin = (
  data: AuthLoginFormPayload
): AppThunk<
  Promise<AxiosResponse<AuthLoginCall["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<AuthLoginCall["response"]["success"]>> => {
  dispatch({
    type: AuthLoginRequestActionType.loading,
  });
  return authAxiosInstance
    .post<AuthLoginCall["response"]["success"]>(
      ApiUrl.AUTH_LOGIN,
      qs.stringify({
        grant_type: "client_credentials",
        client_id: data.username,
        client_secret: data.password,
      } as AuthLoginCall["payload"])
    )
    .then((response) => {
      dispatch({
        type: AuthLoginRequestActionType.successful,
        payload: response.data,
      });
      return response;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: AuthLoginRequestActionType.failed,
        payload: generateErrorFromResponse<AuthApiError>(error),
      });
      throw error;
    });
};

export const authLogout = (): AppThunk<Promise<boolean>> => async (
  dispatch
): Promise<boolean> => {
  dispatch({
    type: AuthLoginRequestActionType.reset,
  });
  return true;
};
