import { applyMiddleware, createStore, compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { rootReducer } from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  { ...window.__PRELOADED_STATE__ },
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware))
);

export type Dispatch = typeof store.dispatch;
