import { AxiosError } from "axios";

import { Error } from "./typings";

export const generateErrorFromResponse = <F>(
  error: AxiosError<F>
): Error<F> => {
  return {
    code: error.code || error.response?.status?.toString() || "",
    title: error.message || "Error",
    response: error.response?.data || null,
  };
};
