import { SettlementAction, SettlementReducerState } from "./typings";
import { MISGetSettlementRequestActionType } from "./constants";
import {
  loadableErrored,
  loadableInProgress,
  loadableLoaded,
  loadableNotInitiated,
} from "../loadable/loadable";
import { MISUploadRequestActionType } from "../MIS/constants";
import { AuthLoginRequestActionType } from "../auth/constants";

export const defaultState: SettlementReducerState = {
  settlementsLoadable: loadableNotInitiated(),
};

export const settlementReducer = (
  state: SettlementReducerState = defaultState,
  action: SettlementAction
): SettlementReducerState => {
  switch (action.type) {
    case MISGetSettlementRequestActionType.loading:
      return {
        ...state,
        settlementsLoadable: loadableInProgress(),
      };

    case MISGetSettlementRequestActionType.successful:
      return {
        ...state,
        settlementsLoadable: loadableLoaded(action.payload),
      };

    case MISGetSettlementRequestActionType.failed:
      return {
        ...state,
        settlementsLoadable: loadableErrored(action.payload),
      };

    case MISUploadRequestActionType.reset:
    case AuthLoginRequestActionType.reset:
      return {
        ...state,
        settlementsLoadable: loadableNotInitiated(),
      };

    default:
      return { ...state };
  }
};
