import { AxiosResponse, AxiosError } from "axios";

import { PaymentChannel } from "../MIS/constants";
import { MISData } from "../MIS/typings";
import { ApiUrl } from "../api/constants";
import { backendAxiosInstance } from "../api/axios";
import { MISGetSettlementRequestActionType } from "./constants";
import { GetSettlementDetailsCall, InitliazeSettlementCall } from "./typings";
import { AppThunk, BEApiError } from "../api/typings";
import { authLogout } from "../auth/actions";
import { generateErrorFromResponse } from "../api/utils";

export const initializeSettlement = (
  paymentChannel: PaymentChannel,
  mis: MISData[],
  trigger_settlement: boolean
): AppThunk<
  Promise<AxiosResponse<InitliazeSettlementCall["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<InitliazeSettlementCall["response"]["success"]>> => {
  dispatch({
    type: MISGetSettlementRequestActionType.loading,
  });
  return backendAxiosInstance
    .post<InitliazeSettlementCall["response"]["success"]>(ApiUrl.MIS_SETTLE, {
      paymentChannel,
      mis,
      trigger_settlement,
    } as GetSettlementDetailsCall["payload"])
    .then((response) => {
      // Creating multiple entries as MerchantReferenceId is array
      const updatedResponse: InitliazeSettlementCall["response"]["success"] = {
        ...response.data,
        settlements: response.data.settlements.reduce<
          InitliazeSettlementCall["response"]["success"]["settlements"]
        >((a, c) => {
          c.MerchantReferenceId.forEach((mr, index) => {
            a.push({
              ...c,
              MerchantReferenceId: [mr],
              platformBillID: [c.platformBillID[index]],
            });
          });
          return a;
        }, []),
      };
      dispatch({
        type: MISGetSettlementRequestActionType.successful,
        payload: updatedResponse,
      });
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
        dispatch({
          type: MISGetSettlementRequestActionType.failed,
          payload: generateErrorFromResponse<BEApiError>(error),
        });
      }
      throw error;
    });
};
