import { AxiosResponse, AxiosError } from "axios";

import { MISData, MISUploadCall, MISProcessRequest, MISProcessResponse } from "./typings";
import { MISUploadRequestActionType, PaymentChannel } from "./constants";
import { ApiUrl } from "../api/constants";
import { backendAxiosInstance } from "../api/axios";
import { AppThunk, BEApiError } from "../api/typings";
import { generateErrorFromResponse } from "../api/utils";
import { authLogout } from "../auth/actions";

export const uploadMIS = (
  paymentChannel: PaymentChannel,
  mis: MISData[]
): AppThunk<
  Promise<AxiosResponse<MISUploadCall["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<MISUploadCall["response"]["success"]>> => {
  dispatch({
    type: MISUploadRequestActionType.loading,
  });
  return backendAxiosInstance
    .post<MISUploadCall["response"]["success"]>(ApiUrl.MIS_UPLOAD, {
      paymentChannel,
      mis,
    } as MISUploadCall["payload"])
    .then((response) => {
      dispatch({
        type: MISUploadRequestActionType.successful,
        payload: response.data,
      });
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
        dispatch({
          type: MISUploadRequestActionType.failed,
          payload: generateErrorFromResponse<BEApiError>(error),
        });
      }
      throw error;
    });
};

// send this body to rivendell and verify if response["success"] is true
// {
//   "paymentChannel" : "phonepe"
// }
export const triggerMISProcessing = (
  paymentChannel: PaymentChannel
): AppThunk<
  Promise<AxiosResponse<MISProcessResponse["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<MISProcessResponse["success"]>> => {
  dispatch({
    type: MISUploadRequestActionType.loading,
  });
  return backendAxiosInstance
    .post<MISProcessResponse["success"]>(ApiUrl.MIS_PROCESS, {
      paymentChannel,
    } as MISProcessRequest)
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
      }
      throw error;
    });
}

export const sendClearingMail = (
  paymentChannel: PaymentChannel
): AppThunk<
  Promise<AxiosResponse<MISProcessResponse["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<MISProcessResponse["success"]>> => {
  dispatch({
    type: MISUploadRequestActionType.loading,
  });
  return backendAxiosInstance
    .post<MISProcessResponse["success"]>(ApiUrl.CLEARING, {
      paymentChannel,
    } as MISProcessRequest)
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
      }
      throw error;
    });
}
