import { AxiosResponse, AxiosError } from "axios";
import { ApiUrl } from "../api/constants";
import { backendAxiosInstance } from "../api/axios";
import { MsgTypeType,MsgTypeActionType } from "./constants";
import { WorkflowRequest } from "./typings";
import { AppThunk, BEApiError } from "../api/typings";
import { authLogout } from "../auth/actions";
import { generateErrorFromResponse } from "../api/utils";

export const initializeWorkflow = (
  msgType: MsgTypeType,
): AppThunk<
  Promise<AxiosResponse<WorkflowRequest["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<WorkflowRequest["response"]["success"]>> => {
    return backendAxiosInstance
      .post<WorkflowRequest["response"]["success"]>(ApiUrl.WORKFLOW, {
        msgType,
      } as WorkflowRequest["payload"])
      .then((response) => {
        dispatch({
          type: MsgTypeActionType.successful,
          payload: MsgTypeActionType.successful,
        });
        return response;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(authLogout());
        } else {
          dispatch({
            type: MsgTypeActionType.failed,
            payload: generateErrorFromResponse<BEApiError>(error),
          });
        }
        throw error;
      });
  };
