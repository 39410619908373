import { AxiosResponse, AxiosError } from "axios";

import { Transaction } from "./typings";
import { UpdateTransactionsCall } from "./typings";
import { backendAxiosInstance } from "../api/axios";
import { ApiUrl } from "../api/constants";
import { AppThunk, BEApiError } from "../api/typings";
import { MISUpdateTransactionsRequestActionType } from "./constants";
import { authLogout } from "../auth/actions";
import { generateErrorFromResponse } from "../api/utils";

export const updateTransactions = (
  transactions: Partial<Transaction>[]
): AppThunk<
  Promise<AxiosResponse<UpdateTransactionsCall["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<UpdateTransactionsCall["response"]["success"]>> => {
  dispatch({
    type: MISUpdateTransactionsRequestActionType.loading,
  });
  return backendAxiosInstance
    .patch<UpdateTransactionsCall["response"]["success"]>(
      ApiUrl.MIS_UPDATE_TRANSACTIONS,
      {
        transactions,
      } as UpdateTransactionsCall["payload"]
    )
    .then((response) => {
      dispatch({
        type: MISUpdateTransactionsRequestActionType.successful,
        payload: response.data,
      });
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
        dispatch({
          type: MISUpdateTransactionsRequestActionType.failed,
          payload: generateErrorFromResponse<BEApiError>(error),
        });
      }
      throw error;
    });
};
