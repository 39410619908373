import Axios from "axios";

export const backendAxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 30 * 60 * 1000,
});

export const authAxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});
