export enum TransactionStatus {
  BILL_CREATED = "BILL_CREATED",
  PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  CREDIT_RECEIVED = "CREDIT_RECEIVED",
  SETTLEMENT_SUCCESSFUL = "SETTLEMENT_SUCCESSFUL",
  SETTLEMENT_FAILED = "SETTLEMENT_FAILED",
  BILL_EXPIRED = "BILL_EXPIRED",
}

export enum TransactionNetwork {
  BBPS = "BBPS",
}

export enum TransactionPaymentMode {
  UPI = "UPI",
}

export enum MISUpdateTransactionsRequestActionType {
  loading = "MIS_UPDATE_TRANSACTION_REQUESTING",
  successful = "MIS_UPDATE_TRANSACTION_REQUESTED",
  failed = "MIS_UPDATE_TRANSACTION_REQUEST_FAILED",
}
