import React, { ChangeEvent, useState } from "react";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Handsontable from "handsontable";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import HotTable from "@handsontable/react";
import fileDownload from "js-file-download";
import get from "lodash/get";

import { PaymentChannel, MISUploadActionType } from "../../data/MIS/constants";
import { MISData } from "../../data/MIS/typings";
import { RootState } from "../../data/reducers";
import { initializeSettlement } from "../../data/settlement/actions";
import {
  hasLoadableLoaded,
  isLoadableInProgress,
  hasLoadableErrored,
} from "../../data/loadable/loadable";
import { Routes } from "../../App";

export const SettlementMISUpload = () => {
  const [paymentChannel, setPaymentChannel] = useState<PaymentChannel | "">("");
  const history = useHistory();

  const settlementsLoadable = useSelector(
    (state: RootState) => state.settlementReducer.settlementsLoadable
  );

  const misInputLoadable = useSelector(
    (state: RootState) => state.misReducer.misInputLoadable
  );

  const dispatch = useDispatch();

  const columns: Handsontable.ColumnSettings[] = [
    {
      data: "id",
      type: "text",
      readOnly: true,
      name: "Settlement ID",
    },
    {
      data: "platformBillID.0",
      type: "text",
      readOnly: true,
      name: "Setu Bill ID",
    },
    {
      data: "MerchantReferenceId.0",
      type: "text",
      readOnly: true,
      name: "Txn Ref ID",
    },
    {
      data: "status",
      type: "text",
      readOnly: true,
      name: "Status",
    },
    {
      data: "settlementDate",
      type: "date",
      readOnly: true,
      dateFormat: "YYYY-MM-DDTHH:mm:ssZ",
      name: "Settlement date",
    },
    {
      data: "UTR",
      type: "text",
      readOnly: true,
      name: "UTR",
    },
  ];

  const handlePCOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPaymentChannel(event.currentTarget.value as PaymentChannel);
  };

  const handleFileOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (paymentChannel && files?.length) {
      Papa.parse<MISData>(files[0], {
        complete: (result) => {
          dispatch({
            type: MISUploadActionType.successful,
            payload: {
              paymentChannel,
              mis: result.data,
            },
          });

          dispatch(initializeSettlement(paymentChannel, result.data, false));
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const renderInputs = () => (
    <>
      <Row className="mt-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Step 1: Select the payment channel</Form.Label>
            <Form.Control
              as="select"
              name="paymentChannel"
              onChange={handlePCOnChange}
              value={paymentChannel}
            >
              <option value={""} disabled>
                Select the channel
              </option>
              {Object.keys(PaymentChannel).map((pc) => {
                return (
                  <option key={pc} value={pc.toLowerCase()}>
                    {pc}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4}>
          {paymentChannel && (
            <Form.Group>
              <Form.Label>Step 2: Upload MIS</Form.Label>
              <Form.File onChange={handleFileOnChange} accept=".csv" />
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoadableInProgress(settlementsLoadable) && (
            <Spinner animation="border" />
          )}
          {hasLoadableErrored(settlementsLoadable) && (
            <Alert variant="danger">
              Upload file failed. Please recheck.
              <br />
              Detail: {settlementsLoadable.error.title}
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );

  const renderTable = () => {
    if (!hasLoadableLoaded(settlementsLoadable)) {
      return null;
    }

    if (!settlementsLoadable.data.settlements.length) {
      return <div>No transactions found</div>;
    }

    return (
      <HotTable
        licenseKey={"non-commercial-and-evaluation"}
        settings={{
          data: settlementsLoadable.data.settlements,
          columns,
          colHeaders: columns.map((a) => a.name),
          rowHeaders: true,
          manualColumnResize: true,
          filters: true,
          dropdownMenu: [
            "filter_by_condition",
            "filter_operators",
            "filter_by_condition2",
            "filter_by_value",
            "filter_action_bar",
          ],
          contextMenu: false,
          columnSorting: true,
        }}
      />
    );
  };

  const renderActions = () => {
    if (
      !hasLoadableLoaded(misInputLoadable) ||
      !hasLoadableLoaded(settlementsLoadable)
    ) {
      return null;
    }

    if (!settlementsLoadable.data.settlements.length) {
      return null;
    }

    return (
      <>
        <Button
          variant="primary"
          onClick={() => {
            history.push(Routes.CLEARING_MIS_UPLOAD);
          }}
        >
          Retry settlement
        </Button>{" "}
        <Button
          variant="dark"
          type="button"
          disabled={
            !hasLoadableLoaded(settlementsLoadable) ||
            !settlementsLoadable.data.settlements.length
          }
          onClick={() => {
            if (!hasLoadableLoaded(settlementsLoadable)) {
              return;
            }

            const keyMappings: Dict<string> = columns.reduce((a, c) => {
              if (typeof c.data === "string") {
                a[c.data] = c.name;
              }
              return a;
            }, {});

            const csv = settlementsLoadable.data.settlements.map((t) => {
              const row: Object = {};

              Object.entries(keyMappings).forEach(([key, value]) => {
                row[value] = get(t, key, null);
              });

              return row;
            });

            fileDownload(Papa.unparse(csv), `settlement.csv`);
          }}
        >
          Download settlement file
        </Button>{" "}
        <Button
          variant="info"
          onClick={() => {
            dispatch(
              initializeSettlement(
                misInputLoadable.data.paymentChannel,
                misInputLoadable.data.mis,
                false
              )
            );
          }}
        >
          Refresh
        </Button>
      </>
    );
  };

  return (
    <div>
      {renderInputs()}
      <div className="mb-3">{renderActions()}</div>
      {renderTable()}
    </div>
  );
};
