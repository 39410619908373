import {
  LoadableStatus,
  Loadable,
  LoadableSuccessful,
  LoadableNotInitiated,
  LoadableInProgress,
  LoadableErrored,
} from "./typings";

export const loadableNotInitiated = <S, F>(): Loadable<S, F> => ({
  status: LoadableStatus.NOT_INTIATED,
});

export const loadableInProgress = <S, F>(): Loadable<S, F> => ({
  status: LoadableStatus.IN_PROGRESS,
});

export const loadableLoaded = <S, F>(data: S): Loadable<S, F> => ({
  status: LoadableStatus.SUCCESSFUL,
  data,
});

export const loadableErrored = <S, F>(error: F): Loadable<S, F> => ({
  status: LoadableStatus.FAILED,
  error,
});

export const hasLoadableLoaded = <S, F>(
  l: Loadable<S, F>
): l is LoadableSuccessful<S> => {
  return l.status === LoadableStatus.SUCCESSFUL;
};

export const isLoadableNotInitiated = <S, F>(
  l: Loadable<S, F>
): l is LoadableNotInitiated => {
  return l.status === LoadableStatus.NOT_INTIATED;
};

export const isLoadableInProgress = <S, F>(
  l: Loadable<S, F>
): l is LoadableInProgress => {
  return l.status === LoadableStatus.IN_PROGRESS;
};

export const hasLoadableErrored = <S, F>(
  l: Loadable<S, F>
): l is LoadableErrored<F> => {
  return l.status === LoadableStatus.FAILED;
};
