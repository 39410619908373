import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { initializeWorkflow } from "../../data/workflow/actions";
import { MsgTypeType } from "../../data/workflow/constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { PaymentChannel } from "../../data/MIS/constants";
import { triggerMISProcessing, sendClearingMail } from "../../data/MIS/actions";
import { initializeSettlement } from "../../data/settlement/actions";

export const Workflow = () => {
	const dispatch = useDispatch();
	const [paymentChannel, setPaymentChannel] = useState<PaymentChannel | "">("");
	const handlePCOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setPaymentChannel(event.currentTarget.value as PaymentChannel);
	};
	const [workflowMsg, setWorkflowMsg] = useState<string>("");
	const [msgType, setMsgType] = useState<string>();

	const onClickWorkflowInitiate = (msgType: MsgTypeType) => {
		setMsgType(msgType);
		const initializeWorkflowCall = () => {
			dispatch(initializeWorkflow(msgType))
				// @ts-ignore
				.then(() => {
					setWorkflowMsg("Initiated : " + msgType);
				})
				.catch(() => {
					setWorkflowMsg("Error initiating : " + msgType);
				});
		};
		initializeWorkflowCall();
	};

	const renderActions = () => {
		return (
			<>
				<Row xs={2} md={4} lg={6}>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => onClickWorkflowInitiate(MsgTypeType.mis_dispatcher)}
						>
							Process Mis Files
						</Button>
					</Col>
					<Col>
						<div>{msgType === MsgTypeType.mis_dispatcher && workflowMsg}</div>
					</Col>
				</Row>
				<br></br>
				<Row xs={2} md={4} lg={6}>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => onClickWorkflowInitiate(MsgTypeType.payout_dispatcher)}
						>
							Process Payout File
						</Button>
					</Col>
					<Col>
						<div>{msgType === MsgTypeType.payout_dispatcher && workflowMsg}</div>
					</Col>
				</Row>
				<br></br>
				<Row xs={2} md={4} lg={6}>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => onClickWorkflowInitiate(MsgTypeType.kotak_refund_initiator)}
						>
							Initiate Kotak Refund
						</Button>
					</Col>
					<Col>
						<div>{msgType === MsgTypeType.kotak_refund_initiator && workflowMsg}</div>
					</Col>
				</Row>
				<br></br>
				<Row xs={2} md={4} lg={6}>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => onClickWorkflowInitiate(MsgTypeType.umap_refund_initiator)}
						>
							Initiate UMAP Refunds
						</Button>
					</Col>
					<Col>
						<div>{msgType === MsgTypeType.umap_refund_initiator && workflowMsg}</div>
					</Col>
				</Row>
				<br></br>
				<Row xs={2} md={4} lg={6}>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => onClickWorkflowInitiate(MsgTypeType.umap_refund_dispatcher)}
						>
							Dispatch UMAP Refund
						</Button>
					</Col>
					<Col>
						<div>{msgType === MsgTypeType.umap_refund_dispatcher && workflowMsg}</div>
					</Col>
				</Row>
				<br></br>
				<Row>
					<Col md={4}>
						<Form.Group>
							<Form.Control
								as="select"
								name="paymentChannel"
								onChange={handlePCOnChange}
								value={paymentChannel}
							>
								<option value={""} disabled>
									Select the channel
								</option>
								{Object.keys(PaymentChannel)
									.filter((pc) => pc !== "UPIDL")
									.map((pc) => {
										return (
											<option key={pc} value={pc.toLowerCase()}>
												{pc}
											</option>
										);
									})}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => dispatch(triggerMISProcessing(paymentChannel as PaymentChannel))}
						>
							Trigger MIS Processing
						</Button>
					</Col>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => dispatch(sendClearingMail(paymentChannel as PaymentChannel))}
						>
							Send Clearing Mail
						</Button>
					</Col>
					<Col>
						<Button
							type="button"
							variant="primary"
							onClick={() => dispatch(initializeSettlement(paymentChannel as PaymentChannel, [], true))}
						>
							Initiate Settlement
						</Button>
					</Col>
				</Row>
			</>
		);
	};

	return (
		<>
			<div className="mb-3">{renderActions()}</div>
		</>
	);
};
