export enum MsgTypeType {
  mis_dispatcher = "MIS_DISPATCHER",
  payout_dispatcher  = "PAYOUT_DISPATCHER",
  kotak_refund_initiator = "KOTAK_REFUND_INITIATOR",
  umap_refund_initiator = "UMAP_REFUND_INITIATOR",
  umap_refund_dispatcher = "UMAP_REFUND_DISPATCHER",
  refund_dispatcher = "REFUND_DISPATCHER",
}

export enum MsgTypeActionType {
  loading = "WORKFLOW_REQUESTING",
  successful = "WORKFLOW_SUCCESSFUL",
  failed = "WORKFLOW_REQUEST_FAILED",
  reset = "WORKFLOW_REQUEST_RESET",
}