import { AuthAction, AuthReducerState, AuthLoginCall } from "./typings";
import { AuthLoginRequestActionType } from "./constants";
import {
  loadableErrored,
  loadableInProgress,
  loadableLoaded,
  loadableNotInitiated,
} from "../loadable/loadable";
import { backendAxiosInstance } from "../api/axios";

const settlementUserStringified = localStorage.getItem("settlementUser");
let settlementUser: AuthLoginCall["response"]["success"] | null = null;

if (settlementUserStringified) {
  settlementUser = JSON.parse(settlementUserStringified);
  if (settlementUser) {
    backendAxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${settlementUser.access_token}`;
  }
}

export const defaultState: AuthReducerState = {
  authLoadable: settlementUser
    ? loadableLoaded(settlementUser)
    : loadableNotInitiated(),
};

export const authReducer = (
  state: AuthReducerState = defaultState,
  action: AuthAction
): AuthReducerState => {
  switch (action.type) {
    case AuthLoginRequestActionType.loading:
      return {
        ...state,
        authLoadable: loadableInProgress(),
      };

    case AuthLoginRequestActionType.successful:
      localStorage.setItem("settlementUser", JSON.stringify(action.payload));
      backendAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.access_token}`;
      return {
        ...state,
        authLoadable: loadableLoaded(action.payload),
      };

    case AuthLoginRequestActionType.failed:
      return {
        ...state,
        authLoadable: loadableErrored(action.payload),
      };

    case AuthLoginRequestActionType.reset:
      localStorage.removeItem("settlementUser");
      if (backendAxiosInstance.defaults.headers.common["Authorization"]) {
        delete backendAxiosInstance.defaults.headers.common["Authorization"];
      }
      return {
        ...state,
        authLoadable: loadableNotInitiated(),
      };

    default:
      return { ...state };
  }
};
