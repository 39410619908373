export enum PaymentChannel {
  PhonePe = "PhonePe",
  ICICI = "ICICI",
  Kotak = "Kotak",
  Common = "Common",
  UPIDL = "UPIDL",
  Axis = "Axis"
}

export enum MISUploadActionType {
  successful = "MIS_UPLOADED",
}

export enum MISUploadRequestActionType {
  loading = "MIS_UPLOAD_REQUESTING",
  successful = "MIS_UPLOAD_REQUESTED",
  failed = "MIS_UPLOAD_REQUEST_FAILED",
  reset = "MIS_UPLOAD_REQUEST_RESET",
}
