import React, { useState, ChangeEvent, FormEvent } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";

import { AuthLoginFormPayload } from "../../data/auth/typings";
import { authLogin } from "../../data/auth/actions";
import { RootState } from "../../data/reducers";
import {
  isLoadableInProgress,
  hasLoadableErrored,
} from "../../data/loadable/loadable";

export const Login = () => {
  const authLoadable = useSelector(
    (state: RootState) => state.authReducer.authLoadable
  );
  const [form, setForm] = useState<AuthLoginFormPayload>({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity()) {
      dispatch(authLogin(form));
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="username">
              <Form.Label>User name</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter username"
                value={form.username}
                onChange={onChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={form.password}
                onChange={onChange}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={isLoadableInProgress(authLoadable)}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {isLoadableInProgress(authLoadable) && <Spinner animation="border" />}
          {hasLoadableErrored(authLoadable) && (
            <Alert variant="danger">
              Login failed. Please check credentials and retry.
              <br />
              Detail: {authLoadable.error.title}
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
