export enum LoadableStatus {
  NOT_INTIATED = "NOT_INTIATED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

export interface LoadableSuccessful<S> {
  status: LoadableStatus.SUCCESSFUL;
  data: S;
}

export interface LoadableErrored<F> {
  status: LoadableStatus.FAILED;
  error: F;
}

export interface LoadableInProgress {
  status: LoadableStatus.IN_PROGRESS;
}

export interface LoadableNotInitiated {
  status: LoadableStatus.NOT_INTIATED;
}

export type Loadable<S, F> =
  | LoadableNotInitiated
  | LoadableInProgress
  | LoadableErrored<F>
  | LoadableSuccessful<S>;
