import { combineReducers } from "redux";

import { misReducer } from "./MIS/reducer";
import { settlementReducer } from "./settlement/reducer";
import { authReducer } from "./auth/reducer";
import { workflowReducer } from "./workflow/reducer";

export const rootReducer = combineReducers({
  misReducer,
  settlementReducer,
  authReducer,
  workflowReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
