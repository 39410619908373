import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  NavLink,
  RouteProps,
} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";

import { authLogout } from "./data/auth/actions";
import { ClearingTransaction } from "./pages/ClearingTransaction/ClearingTransaction";
import { ClearingMISUpload } from "./pages/ClearingMISUpload/ClearingMISUpload";
import { SettlementMISUpload } from "./pages/SettlementMISUpload/SettlementMISUpload";
import { Workflow } from "./pages/workflow/workflow";

import { RootState } from "./data/reducers";
import { hasLoadableLoaded } from "./data/loadable/loadable";
import { Login } from "./pages/Login/Login";
import { Error } from "./pages/Error/Error";

import "handsontable/dist/handsontable.full.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./logo-dp-on-tp.svg";

export enum Routes {
  CLEARING_MIS_UPLOAD = "/",
  CLEARING_TRANSACTION = "/clearing/transactions",
  SETTLEMENT_MIS_UPLOAD = "/settlement",
  AUTH_LOGIN = "/login",
  SETTLEMENT_WORKFLOW = "/workflow"
}

const Header = () => {
  const authLoadable = useSelector(
    (state: RootState) => state.authReducer.authLoadable
  );
  const dispatch = useDispatch();
  return (
    <Navbar bg="light" expand="lg">
      <NavLink to={Routes.CLEARING_MIS_UPLOAD} className="navbar-brand">
        <img alt="Setu logo" src={logo} height="30" className="align-top" />{" "}
        Settlement Dashboard
      </NavLink>
      {hasLoadableLoaded(authLoadable) && (
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavLink
                to={Routes.CLEARING_MIS_UPLOAD}
                className="nav-link"
                exact
              >
                Clearing
              </NavLink>
              <NavLink to={Routes.SETTLEMENT_MIS_UPLOAD} className="nav-link">
                Settlement
              </NavLink>
              <NavLink to={Routes.SETTLEMENT_WORKFLOW} className="nav-link">
                Workflow Trigger
              </NavLink>
            </Nav>
            <Nav>
              <Navbar.Text>Hi, </Navbar.Text>
              <Nav.Link
                href="#"
                onClick={() => {
                  dispatch(authLogout());
                }}
              >
                Logout?
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
};

export const LoggedInRoute = (props: RouteProps) => {
  const authLoadable = useSelector(
    (state: RootState) => state.authReducer.authLoadable
  );
  if (hasLoadableLoaded(authLoadable)) {
    return <Route {...props} />;
  }
  return <Route {...props} component={Login} />;
};

export const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Container className="mt-3">
        <Switch>
          <LoggedInRoute
            exact
            path={Routes.CLEARING_TRANSACTION}
            component={ClearingTransaction}
          />
          <LoggedInRoute
            exact
            path={Routes.CLEARING_MIS_UPLOAD}
            component={ClearingMISUpload}
          />
          <LoggedInRoute
            exact
            path={Routes.SETTLEMENT_MIS_UPLOAD}
            component={SettlementMISUpload}
          />
          <LoggedInRoute
            exact
            path={Routes.SETTLEMENT_WORKFLOW}
            component={Workflow}
          />
          <Route component={Error} />
        </Switch>
      </Container>
    </BrowserRouter>
  );
};
